
  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border: none;
}
::v-deep .is-active{
            background-color: white;
            // color: red;

    }
.refresh-bind {
    margin-left: 10px;

}
::v-deep .el-tabs__header{
      margin: 0;
    }
.live-container {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  .live-top {
    display: flex;
    align-items: center;
    .live-top-left,.live-top-tight{
      position: relative;
      .platform-cover{
        position: absolute;
        width: 26px;
        height: 26px;
        left: 10px;
        top: calc(50% - 13px);
        border-radius: 50%;
      }
    }
    .platform-select, .account-select {
      width: 210px;

      ::v-deep .el-input__inner {
        height: 40px;
        line-height: 40px;
      }
      &.platform-select
      ::v-deep .el-input__icon {
        line-height: 40px;
      }

      &.platform-select {
        margin-right: 20px;
      }

      ::v-deep.el-select-dropdown {
        .el-select-dropdown__list {
          .el-select-dropdown__item {
            display: flex;
            align-items: center;
            padding: 0 10px;
            img {
              margin-right: 10px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .live-bottom{
    display: flex;
    position: relative;
    margin-top: 18px;
    flex: 1;
    height: 1%;
    ::v-deep.el-tabs{
      box-shadow: none;
      border: none;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background: transparent;
      flex: 1;
      width: 1%;

      position: relative;
    }
    ::v-deep.el-tabs__header{
      border-bottom: none;
      margin: 0;
    }
    ::v-deep.el-tabs__content{
      flex: 1;
      background: #fff;
      padding-top: 20px;
      .el-tab-pane:nth-child(1){
        min-height: 810px;
      }
    }
    .el-tab-pane{
      height: 100%;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
      background: #fff;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
      border-left: none;
    }
    ::v-deep.el-tabs__header .is-active {
      background: #fff;
      font-size: 16px;
      font-weight: 400;
      color: #0824ce;
      border-radius: 6px 6px 0 0;
    }
    ::v-deep.el-tabs__item{
      height: 45px;
      line-height: 45px;
      padding: 0 40px!important;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
      border: none;
    }
    ::v-deep.el-tabs__item:hover {
      color: #0824ce;
      cursor: pointer;
    }
    .update-content{
      height: 20px;
      position: absolute;
      top: 20px;
      right: 378px;
      .update-title{
        margin: 0 10px;
        font-size: 14px;
        cursor: pointer;
        i{
          color: #0824ce;
          margin-right: 10px;
        }
        &:hover{
          color: #1D33E1;
        }
      }
    }
  }
}
.platform-select-img{
  ::v-deep .el-input__inner {
    padding: 0 15px 0 42px;
  }
}
.qr-code-box{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  i.iconfont{
    font-size: 24px;
    color: #fff;
    position: absolute;
    right: 4%;
    top: 4%;
    cursor: pointer;
  }
  .qr-code{
      position: relative;
    width: 340px;
    height: 340px;
      .qr-code-mask {
          .qr-code-timeout {
              display: flex;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(255,255,255,0.93 );
              justify-content: center;
              align-items: center;
              span.refresh-warning {
                  font-weight: bold;
                  font-size: 16px;
                  i.el-icon-warning {
                      color: #ff4f26;
                  }
                  color: #666666;
                  a.refresh {
                      cursor: pointer;
                      text-decoration: underline;
                      font-size: 18px;
                  }
              }
          }
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
      }

    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  span{
    color: #fff;
    &:first-of-type{
      font-size: 36px;
      margin: 30px 0;
    }
    &:last-of-type{
      font-size: 16px;
    }
  }
}
